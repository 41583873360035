import React from 'react'
import LayoutComponent from '../../../components/documentation-layout/Layout'

const ExecuteTestCycle = () => {
  return (
    <LayoutComponent>
      <h3>Executing a Test Cycle</h3>
      <p>On the Cycles view, click <strong>Play</strong> icon that will lead to the Test Player.</p>

      <div className='testimonials'>
        <h3>Note</h3>
        These options allow you to begin testing or continue testing.
      </div>

      <p>The Test Player is where actual testing occurs and is tracked within GAINSS. Regardless of how many test cases you want to execute, the Test Player is where the fun happens.</p>

      <h2>Update and Track Your Progress</h2>
      <p>In the Test Player, you can update the status and start the test-execution timer. To improve the accuracy of your planning, the Test Player tracks testing estimates and actual testing time. Time summaries appear at both the test-cycle level as well as at the test-case level.</p>

      <p>Click play to begin testing. The test execution timer begins to run.</p>

      {/* <p>You can also enter testing time manually by clicking the ellipses button, entering the hours and minutes (hh:mm), and clicking Save.</p> */}

      <p>The status of the test case updates as you input test-steps results.</p>

      {/* <h2>Group Tests</h2>
      <p>The group-by option in the Test Player allows you to filter and reorganize tests into more useful views.</p>

      <p>Click the <strong>Group By</strong> dropdown to select from available grouping options.</p>

      <p>The test cases restructure to give you a better sense of the data you're looking for. For example, this set of test cases is grouped by status.</p> */}

      <h2>Executing Test Cases/Steps</h2>
      <p>Complete testing for each test case and set the result for each test step by clicking the <strong>Pass</strong>, <strong>Fail</strong>, or <strong>Blocked</strong> icons.</p>

      <p>If necessary, you can update the results of any remaining steps in bulk. </p>

    </LayoutComponent>
  )
}

export default ExecuteTestCycle